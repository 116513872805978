/*
  modified : Ashutosh G
  modified : 21/05/2024
  modification : overlay css
*/
/* Overlay.css */
#overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  display: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.visible {
  display: flex; /* Show overlay when visible */
}

.hidden {
  display: none; /* Hide overlay when hidden */
}

.overlay-box {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay-content {
  font-size: 1rem;
  font-weight: bold;
  color: crimson;
}
